import { createToastInterface } from "vue-toastification";

const toast = createToastInterface();

export default function handleError(error, timeout = false) {
  if (!toast) {
    console.error("Toast instance is not initialized.");
    return;
  }
  const message = `An error occurred: ${error.message || error}`;
  toast.error(message, {
    timeout: timeout,
    draggable: true
  });
}
