import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/index'
import loading from 'vuejs-loading-screen'
import VueDragscroll from 'vue-dragscroll'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import handleError from './error/ErrorHandler'

import 'bootstrap'
import {Tooltip} from 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import './scss/style.scss'
import store from './store'
import { BACKEND_URL } from './config'

Vue.config.productionTip = false
axios.defaults.baseURL = BACKEND_URL

Vue.directive('tooltip', {
  inserted: function (el) {
    new Tooltip(el)
  }
})
Vue.use(VueDragscroll)
Vue.use(loading, {
  bg: '#0a0a0aa1',
  slot: `
    <div class="px-5 py-3">
      <div class="d-flex">
        <h6 class="m-0 align-self-center text-3xl text-white"><i class="fas fa-spinner fa-spin"></i> Loading, please wait...</h6>
        <div class="ms-3 text-white spinner-border spinner-border-md" role="status">
          <span class="visually-hidden">...</span>
        </div>
      </div>
      </div>
    </div>
  `
});

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
});

Vue.config.errorHandler = (err, vm, info) => {
  const errorMessage = `Vue Error Handler:
  - Error: ${err.toString()}
  - Component: ${vm?.$options?.name || 'Anonymous'}
  - Info: ${info}`;

  handleError(new Error(errorMessage));
};

window.onerror = function (msg, url, line, col, error) {
  const errorMessage = `Global Error Handler:
  - Message: ${msg}
  - Source: ${url}
  - Line: ${line}, Column: ${col}
  - Error Object: ${error ? error.toString() : 'N/A'}`;

  handleError(new Error(errorMessage));
  return true;
};

window.addEventListener('unhandledrejection', function (event) {
  const errorMessage = `Unhandled Promise Rejection:
  - Reason: ${event.reason || 'No reason provided'}
  - Event: ${JSON.stringify(event, null, 2)}`;

  handleError(new Error(errorMessage));
});

router.onError((error) => {
  const errorMessage = `Vue Router Error:
  - Error: ${error.message || error.toString()}
  - Route: ${error?.route ? JSON.stringify(error.route, null, 2) : 'N/A'}
  - Stack Trace: ${error.stack || 'No stack trace available'}`;

  handleError(new Error(errorMessage));
});

function initializeApp() {
  console.log("Starting up the application.");
  return new Promise((resolve, reject) => {
    Promise.all([
      store.dispatch("loadBranches"),
      store.dispatch("loadBuildings")
    ])
      .then(() => console.log("Application's data has been initialized..."))
      .then(() => resolve())
      .catch(() => reject());
  });
}

initializeApp().then(() => {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
})

