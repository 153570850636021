import axios from 'axios'
import { BACKEND_URL } from '../config';

const _axios = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' }
})

class BranchService {
  getBranches() {
    return _axios.get(`api/branches`);
  }
  
  getGeofences(branchId) {
    return _axios.get(`api/branches/${branchId}/geofences`);
  }
}

export const branchService = new BranchService();
